<div class="custom-paginator">
  <div class="paginator-details">
    <span class="hide-mobile">
      Showing {{ rangeStart }} – {{ rangeEnd }} of {{ length }} Results
    </span>
    <span class="mobile-only">
      {{ rangeStart }} – {{ rangeEnd }} of {{ length }}
    </span>
  </div>
  <div class="flex flex-row paginator-controls">
    <form [formGroup]="form" class="form-input mr-2">
      <mat-select formControlName="pageSizeControl" class="px-2">
        <mat-option
          *ngFor="let pageSizeOption of pageSizeOptions"
          [value]="pageSizeOption"
        >
          {{ pageSizeOption }}
        </mat-option>
      </mat-select>
    </form>
    <button mat-button (click)="firstPage()" [disabled]="pageIndex === 0">
      <i class="pi pi-angle-double-left mr-2"></i>
      <span>First</span>
    </button>
    <button mat-button (click)="previousPage()" [disabled]="pageIndex === 0">
      <i class="pi pi-angle-left mr-2"></i>
      <span>Prev</span>
    </button>
    <button
      mat-button
      *ngFor="let page of pages"
      (click)="goToPage(page)"
      [class.active]="pageIndex === page"
      [disabled]="pageSize >= length"
      [ngClass]="{ 'hide-mobile': pageIndex !== page }"
    >
      <span>{{ page + 1 }}</span>
    </button>
    <button
      mat-button
      (click)="nextPage()"
      [disabled]="pageIndex >= Math.ceil(length / pageSize) - 1"
    >
      <span>Next</span>
      <i class="pi pi-angle-right ml-2"></i>
    </button>
    <button
      mat-button
      (click)="lastPage()"
      [disabled]="pageIndex >= Math.ceil(length / pageSize) - 1"
    >
      <span>Last</span>
      <i class="pi pi-angle-double-right ml-2"></i>
    </button>
  </div>
</div>
