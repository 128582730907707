import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/services/common.service';
import { InvestorService } from 'src/services/investor.service';
@Component({
  selector: 'app-update-demat-details-modal',
  templateUrl: './update-demat-details-modal.component.html',
  styleUrls: ['./update-demat-details-modal.component.scss'],
})
export class UpdateDematDetailsModalComponent implements OnInit {
  dematDetailForm!: FormGroup;
  loading: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private investorService: InvestorService,
    public dialogRef: MatDialogRef<UpdateDematDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.dematDetailForm = this.formBuilder.group({
      id: [''],
      user_id: [''],
      account_number: ['', [Validators.required]],
      dp_id: ['', [Validators.required]],
      broker_name: ['', [Validators.required]],
      depository: ['', [Validators.required]],
    });
    if (this.data && this.data.demat_account) {
      this.dematDetailForm.patchValue({
        id: this.data.demat_account.id,
        user_id: this.data.user_id,
        account_number: this.data.demat_account.account_number,
        dp_id: this.data.demat_account.dp_id,
        broker_name: this.data.demat_account.broker_name,
        depository: this.data.demat_account.depository,
      });
    }
  }

  submitForm() {
    this.loading = true;
    if (this.dematDetailForm && this.dematDetailForm.valid) {
      this.investorService
        .updateDematDetails(this.dematDetailForm.value)
        .subscribe({
          next: (res: any) => {
            this.commonService.openSnackbar(res.message);
            console.log('Demat Detail Response:', res);
            this.loading = false;
            this.closeModal();
          },
          error: (err: any) => {
            console.error('Demat Detail Error:', err);
            this.loading = false;
            this.commonService.openSnackbar(err.error.message);
          },
        });
    } else {
      this.loading = false;
      this.commonService.openSnackbar('Please provide valid information !');
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
