import { PrimeNgModule } from './prime-ng.module';
import { UserDetailsComponent } from './../components/user-details/user-details.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddNoteModalComponent } from './../components/add-note-modal/add-note-modal.component';
import { UpdateLeadStatusModalComponent } from '../components/update-lead-status-modal/update-lead-status-modal.component';
import { AddCommentModalComponent } from '../components/add-comment-modal/add-comment-modal.component';
import { UpdateDematDetailsModalComponent } from '../components/update-demat-details-modal/update-demat-details-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CustomPagianatorComponent } from '../components/custom-pagianator/custom-pagianator.component';
import { MatSelectModule } from '@angular/material/select';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { LoaderComponent } from '../components/loader/loader.component';

@NgModule({
  declarations: [
    UserDetailsComponent,
    AddNoteModalComponent,
    UpdateLeadStatusModalComponent,
    AddCommentModalComponent,
    UpdateDematDetailsModalComponent,
    CustomPagianatorComponent,
    LoaderComponent,
  ],
  imports: [
    CommonModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatButtonModule,
    AgGridModule,
    AgGridAngular,
  ],
  exports: [
    UserDetailsComponent,
    LoaderComponent,
    AddNoteModalComponent,
    UpdateLeadStatusModalComponent,
    AddCommentModalComponent,
    UpdateDematDetailsModalComponent,
    FormsModule,
    ReactiveFormsModule,
    CustomPagianatorComponent,
    AgGridModule,
    AgGridAngular,
  ],
  providers: [
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSizeOptions: [5, 10, 25, 100],
        showFirstLastButtons: false,
      },
    },
  ],
})
export class SharedModule {}
