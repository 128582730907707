<div class="header">
  <h2>{{ data.demat_account.id ? "Update" : "Add" }} Demat Account Info</h2>
  <i class="pi pi-times" style="font-size: 1.5rem" (click)="closeModal()"></i>
</div>
<p-card class="section">
  <form [formGroup]="dematDetailForm">
    <div class="grid p-fluid">
      <div class="col-12 sm:col-6 md:col-6">
        <h3>Account Number</h3>
        <input
          type="text"
          pInputText
          placeholder="Account Number"
          formControlName="account_number"
        />
      </div>

      <div class="col-12 sm:col-6 md:col-6">
        <h3>DP ID</h3>
        <input
          type="text"
          pInputText
          placeholder="DP ID"
          formControlName="dp_id"
        />
      </div>
    </div>

    <div class="grid p-fluid">
      <div class="col-12 sm:col-6 md:col-6">
        <h3>Depository</h3>
        <input
          type="text"
          pInputText
          placeholder="Depository"
          formControlName="depository"
        />
      </div>
      <div class="col-12 sm:col-6 md:col-6">
        <h3>Broker Name</h3>
        <input
          type="text"
          pInputText
          placeholder="Broker Name"
          formControlName="broker_name"
        />
      </div>
    </div>

    <div class="col-12 flex flex-row justify-content-end">
      <p-button
        pRipple
        type="button"
        [label]="'Save'"
        (click)="submitForm()"
        [disabled]="dematDetailForm.invalid || loading"
      ></p-button>
    </div>
  </form>
</p-card>
