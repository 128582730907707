import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private snackBar: MatSnackBar) {}

  downloadDataAsCSV(data: any, fileName: string) {
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    let csv = data.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    );
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');
    var blob = new Blob([csvArray], { type: 'text/csv' });
    saveAs(blob, fileName + '.csv');
  }

  openSnackbar(message: string) {
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['mat-toolbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  formatChartDateLabelBasedOnType(
    labels: Array<Date>,
    type: string,
  ): Array<any> {
    let fromattedLabel: Array<any> = [];
    if (type === 'daily') {
      fromattedLabel = labels.map((label) =>
        formatDate(new Date(label), 'd MMM', 'en-US'),
      );
    } else if (type === 'weekly') {
      fromattedLabel = labels.map((label) => {
        const startDate = new Date(label);
        let endDate = new Date(label);
        endDate.setDate(endDate.getDate() + 6);
        return (
          formatDate(
            startDate,
            `d${startDate.getMonth() === endDate.getMonth() ? '' : ' MMM'}`,
            'en-US',
          ) +
          ' - ' +
          formatDate(endDate, 'd MMM', 'en-US')
        );
      });
    } else if (type === 'monthly') {
      fromattedLabel = labels.map((label) =>
        formatDate(new Date(label), 'MMM yyyy', 'en-US'),
      );
    } else if (type === 'quarterly') {
      fromattedLabel = labels.map((label) => {
        const startDate = new Date(label);
        let endDate = new Date(label);
        endDate.setMonth(endDate.getMonth() + 2);
        return (
          formatDate(startDate, 'MMM', 'en-US') +
          ' - ' +
          formatDate(endDate, 'MMM yyyy', 'en-US')
        );
      });
    }
    return fromattedLabel;
  }
}
