import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginator,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
} from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-custom-pagianator',
  templateUrl: './custom-pagianator.component.html',
  styleUrls: ['./custom-pagianator.component.scss'],
})
export class CustomPagianatorComponent extends MatPaginator implements OnInit {
  @Input() rangeLabel!: string;

  form: FormGroup;
  pages!: number[];
  Math = Math;
  totalPages!: number;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    intl: MatPaginatorIntl,
    changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS)
    defaults?: MatPaginatorDefaultOptions,
  ) {
    super(intl, changeDetectorRef, defaults);

    this.form = this.fb.group({
      pageSizeControl: [],
    });
    // this.pageIndex = 0;
  }

  get rangeStart(): number {
    return this.pageIndex * this.pageSize + 1;
  }

  get rangeEnd(): number {
    let end = (this.pageIndex + 1) * this.pageSize;
    if (end > this.length) {
      end = this.length;
    }
    return end;
  }

  override ngOnInit(): void {
    // Get query params and patch the form values
    this.route.queryParams.subscribe((params) => {
      this.pageIndex = params['offset'] ? params['offset'] : 0;
      // this.pageSize = params["limit"] ? params["limit"] : 500;
    });

    this.form.get('pageSizeControl')?.patchValue(this.pageSize);
    this.updatePages();
    this.form.get('pageSizeControl')?.valueChanges.subscribe((value) => {
      this.pageSize = value;
      this.pageIndex = 0;
      this.page.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length,
      });
      this.updatePages();
    });
  }

  updatePages() {
    const totalPages = Math.ceil(this.length / this.pageSize);
    const currentPage = this.pageIndex;
    const maxPagesToShow = 3;
    this.totalPages = totalPages;

    let startPage: number, endPage: number;

    if (totalPages <= maxPagesToShow) {
      // Less than maxPagesToShow total pages, so show all pages
      startPage = 0;
      endPage = totalPages - 1;
    } else {
      // More than maxPagesToShow total pages, calculate start and end pages
      const halfPagesToShow = Math.floor(maxPagesToShow / 2);

      if (currentPage <= halfPagesToShow) {
        startPage = 0;
        endPage = maxPagesToShow - 1;
      } else if (currentPage + halfPagesToShow >= totalPages) {
        startPage = totalPages - maxPagesToShow;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - halfPagesToShow;
        endPage = currentPage + halfPagesToShow;
      }
    }

    this.pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i,
    );
  }

  goToPage(page: number) {
    this.pageIndex = page;
    this.page.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      length: this.length,
    });
    this.updatePages();
  }

  override nextPage() {
    if (this.pageIndex < Math.ceil(this.length / this.pageSize) - 1) {
      (this.pageIndex as number) += 1;
      this.page.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length,
      });
      this.updatePages();
    }
  }

  override previousPage() {
    if (this.pageIndex > 0) {
      (this.pageIndex as number) -= 1;
      this.page.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length,
      });
      this.updatePages();
    }
  }

  override firstPage(): void {
    if (this.pageIndex > 0) {
      this.pageIndex = 0;
      this.page.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length,
      });
      this.updatePages();
    }
  }

  override lastPage(): void {
    if (this.pageIndex < Math.ceil(this.length / this.pageSize) - 1) {
      this.pageIndex = Math.ceil(this.length / this.pageSize) - 1;
      this.page.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        length: this.length,
      });
      this.updatePages();
    }
  }
}
